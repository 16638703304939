import { sdk } from './api'
import { EnterpriseSchema, StatsQuery } from '@/generated/sdk'
import { Module } from 'vuex'
export interface AppState {
  apiKey: string,
  enterprises: EnterpriseSchema[],
  enterprise: EnterpriseSchema,
  stats: StatsQuery,
}

export default {

  state: {
    apiKey: localStorage.getItem('apiKey') || null,
    enterprises: null,
    enterprise: null,
    stats: null,
  },

  getters: {
  },

  mutations: {
    setApiKey(state, value: string) {
      // TODO: Reset other stores as well
      localStorage.setItem('apiKey', value)
      state.apiKey = value
      state.enterprises = null
      state.enterprise = null
    },
    setEnterprises(state, value: EnterpriseSchema[]) {
      state.enterprises = value
      state.enterprise = null
    },
    setEnterprise(state, value: EnterpriseSchema) {
      state.enterprise = value
    },
    setStats(state, value: StatsQuery) {
      state.stats = value
    },
  },

  actions: {
    async loadEnterprises({ commit, state }) {
      const { enterprise } = await sdk().enterprises()
      commit('setEnterprises', enterprise)
      commit('setEnterprise', enterprise[0])
    },
    async loadStats({ commit, state }) {
      const stats = await sdk().stats()
      commit('setStats', stats)
    },
  },

} as Module<AppState, any>
