<script setup lang="ts">
import { ref } from 'vue'
import { useStore } from 'vuex'

declare const gapi: any

const store = useStore()

defineProps<{ packageName: string | null }>()
const emit = defineEmits(['update:packageName'])

const showDialog = ref(false)
const container = ref<HTMLElement>(null)

function isHttps(): boolean {
  return location.protocol === 'https:'
}

async function loadPlayStore(): Promise<void> {
  showDialog.value = true

  const { value } = await store.dispatch('createWebToken')
  const url = `https://play.google.com/work/embedded/search?token=${value}&mode=SELECT`

  gapi.load('gapi.iframes', () => {
    const options = {
      url,
      where: container.value,
      attributes: { style: 'width: 1000px; height:900px', scrolling: 'yes' },
    }
    const iframe = gapi.iframes.getContext().openChild(options)
    iframe.register(
      'onproductselect',
      async function (event) {
        if (event.packageName?.length > 0) {
          emit('update:packageName', event.packageName)
        }
        showDialog.value = false
      },
      gapi.iframes.CROSS_ORIGIN_IFRAMES_FILTER,
    )
  })
}
</script>

<template>
  <DodoColumn v-if="isHttps">
    <DodoButton
      square
      variant="solid"
      color="info"
      @click="loadPlayStore()">
      <DodoIcon name="search" />
    </DodoButton>
  </DodoColumn>
  <PrimeDialog
    v-model:visible="showDialog"
    modal
    header="Find app in Play Store"
    :style="{ width: '65vw' }"
    :breakpoints="{ '960px': '75vw', '641px': '100vw' }"
  >
    <div ref="container" style="width: 1000px; height:900px"></div>
  </PrimeDialog>
</template>

<style scoped>
.store-wrapper {
  background-color: #f8f9fa;
  border-radius: 5px;
}
</style>
