<script setup lang="ts">
import { computed, onMounted } from 'vue'
import { DeviceSchema, MdmDeviceSchema, MdmPolicySchema } from '@/generated/sdk'

import { router } from '@/plugins/router'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'

import { useProvideDevice } from '..'
import DeviceDetailsTable from './DeviceDetailsTable.vue'
const { setDevice, device, groupedDeviceDetails } = useProvideDevice()

const route = useRoute()
const store = useStore()
const mdmPolicies = computed<MdmPolicySchema[]>(() => store.state.policies.mdmPolicies)

const mdmDevice = computed<MdmDeviceSchema>(() => device.value.mdmDevice)
const googleDevice = computed<DeviceSchema>(() => device.value.googleDevice)

onMounted(async () => {
  const mdmDeviceId = route.params.id as string
  await setDevice(mdmDeviceId)
})

async function updateMdmDevicePolicy(e: MdmDeviceSchema): Promise<void> {
  const confirmMessage = `Update device with IMEI: ${e.imei} to "${formatPolicyName(e.policyName)}"?`
  if (window.confirm(confirmMessage)) {
    await store.dispatch('updateMdmDevices', [
      {
        id: e.id,
        googleId: e.googleId,
        policyName: e.policyName,
      } as MdmDeviceSchema,
    ])
  }
}

async function deleteDevice(e: MdmDeviceSchema): Promise<void> {
  if (window.confirm(`WARNING: Do you want to delete device with IMEI: ${e.imei}?`)) {
    await store.dispatch('deleteMdmDevice', e)
    await router.replace('/devices')
  }
}

function formatPolicyName(id: string): string {
  return mdmPolicies.value.find(m => m.googleId === id)?.name
}
</script>

<template>
  <div>
    <h1 class="header-padding">Device details</h1>

    <div v-if="mdmDevice != null && googleDevice != null">
      <DodoCard>
        <h3>Policy</h3>
        <DodoRow>
          <PrimeDropdown
            v-model="mdmDevice.policyName"
            :options="mdmPolicies"
            :loading="mdmPolicies == null"
            option-value="googleId"
            option-label="name"
            placeholder="Select a policy"
            :disabled="!googleDevice.id"
            style="min-width: 20%;"
          />

          <DodoButton
            variant="solid"
            :disabled="!googleDevice.id"
            color="info"
            @click="updateMdmDevicePolicy(mdmDevice)"
          >
            <DodoIcon name="checkmark" fill /> Save
          </DodoButton>
          <DodoButton
            variant="solid"
            color="danger"
            @click="deleteDevice(mdmDevice)">
            <DodoIcon name="delete" fill /> Delete
          </DodoButton>
        </DodoRow>

        <DodoRow v-if="mdmDevice.expectedPolicyName">
          <span class="expected-policy">Expected policy: {{ mdmDevice.expectedPolicyName }}</span>
        </DodoRow>
      </DodoCard>

      <DodoCard>
        <h3>Details</h3>

        <DodoTabs
          :tabs="[
            { name: 'General', slot: 'general' },
            { name: 'Enrollment', slot: 'enrollment' },
            { name: 'Hardware', slot: 'hardware' },
            { name: 'Software', slot: 'software' },
            { name: 'Network', slot: 'network' },
            { name: 'Security', slot: 'security' },
          ]"
        >
          <template
            v-for="(group, groupName) in groupedDeviceDetails"
            :key="groupName"
            #[groupName]>
            <DeviceDetailsTable :group="group" :group-name="groupName" />
          </template>
        </DodoTabs>
      </DodoCard>
    </div>
  </div>
</template>

<style scoped>
.dodo-card {
  margin-bottom: 24px;
}
.header-padding {
  padding-bottom: 12px;
}

.label {
  font-weight: bold;
  font-size: 16px;
}

.expected-policy {
  font-style: italic;
}
</style>
