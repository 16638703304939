import { sdk } from './api'
import { DeviceState, MdmDeviceInputSchema, MdmDeviceSchema, SwitchDeviceArgs, SwitchDeviceSchema } from '@/generated/sdk'
import { Module } from 'vuex'
export interface DevicesState {
  mdmDevices: MdmDeviceSchema[],
  switches: SwitchDeviceSchema[],
}

export default {
  state: {
    mdmDevices: null,
    switches: null,
  },

  getters: {
  },

  mutations: {
    setMdmDevices(state, value: MdmDeviceSchema[]) {
      state.mdmDevices = value
    },
    setSwitches(state, value: SwitchDeviceSchema[]) {
      state.switches = value
    },
  },

  actions: {
    async loadMdmDevices({ commit }, data) {
      const { mdmDevice } = await sdk().mdmDevices(data)
      commit('setMdmDevices', mdmDevice)
    },
    async loadSwitches({ commit, rootState }, queryArgs: SwitchDeviceArgs) {
      const { switchDevice } = await sdk().switches({
        input: {
          enterpriseId: rootState.app.enterprise.id,
        },
        queryArgs,
      })
      commit('setSwitches', switchDevice)
    },
    async createMdmDevices({ }, update: MdmDeviceInputSchema[]) {
      for (const d of update) {
        console.log(d)
        await sdk().createMdmDevice({ input: d })
      }
    },
    async updateMdmDevices({ }, update: MdmDeviceSchema[]) {
      for (const d of update) {
        console.log({
          mdmDeviceUpdate: d,
          deviceUpdate: {
            id: d.googleId,
            policyName: d.policyName,
            state: DeviceState.Active,
          },
        })
        await sdk().updateDevicePolicy({
          mdmDeviceUpdate: d,
          deviceUpdate: {
            id: d.googleId,
            policyName: d.policyName,
            state: DeviceState.Active,
          },
        })
      }
    },
    async deleteMdmDevice({ state, commit }, data: MdmDeviceSchema) {
      await sdk().deleteMdmDevice({ mdmDeviceId: data.id })
      if (data.googleId != null) {
        await sdk().deleteDevice({ deviceId: data.googleId })
      }

      commit('setMdmDevices', state.mdmDevices.filter(m => m.id !== data.id))
    },
  },

} as Module<DevicesState, any>
