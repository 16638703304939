import {
  ApplicationPolicyInstallType,
  ApplicationPolicyAutoUpdateMode,
  ApplicationPolicyDelegatedScopes,
  ApplicationPolicyDefaultPermissionPolicy,
  ApplicationPolicyConnectedWorkAndPersonalApp,
  ApplicationPolicyAlwaysOnVpnLockdownExemption,
  ApplicationPolicyWorkProfileWidgets,
  PolicyKeyguardDisabledFeatures,
  PolicyDefaultPermissionPolicy,
  PolicyLocationMode,
  PolicyAppAutoUpdatePolicy,
  PolicyEncryptionPolicy,
  PolicyPlayStoreMode,
  PolicyAutoDateAndTimeZone,
  PolicyPreferentialNetworkService,
  PolicyCameraAccess,
  PolicyMicrophoneAccess,
  PolicyStayOnPluggedModes,
} from '@/generated/sdk'

const applicationInstallTypes = Object.values(ApplicationPolicyInstallType)
const applicationAutoUpdateModes = Object.values(ApplicationPolicyAutoUpdateMode)
const applicationDelegatedScopes = Object.values(ApplicationPolicyDelegatedScopes)
const applicationDefaultPermissionPolicy = Object.values(ApplicationPolicyDefaultPermissionPolicy)
const applicationConnectedWorkAndPersonalApp = Object.values(ApplicationPolicyConnectedWorkAndPersonalApp)
const applicationAlwaysOnVpnLockdownExemption = Object.values(ApplicationPolicyAlwaysOnVpnLockdownExemption)
const applicationWorkProfileWidgets = Object.values(ApplicationPolicyWorkProfileWidgets)
const policyKeyguardDisabledFeatures = Object.values(PolicyKeyguardDisabledFeatures)
const policyDefaultPermissionPolicy = Object.values(PolicyDefaultPermissionPolicy)
const policyLocationMode = Object.values(PolicyLocationMode)
const policyAppAutoUpdatePolicy = Object.values(PolicyAppAutoUpdatePolicy)
const policyEncryptionPolicy = Object.values(PolicyEncryptionPolicy)
const policyPlayStoreMode = Object.values(PolicyPlayStoreMode)
const policyAutoDateAndTimeZone = Object.values(PolicyAutoDateAndTimeZone)
const policyPreferentialNetworkService = Object.values(PolicyPreferentialNetworkService)
const policyCameraAccess = Object.values(PolicyCameraAccess)
const policyMicrophoneAccess = Object.values(PolicyMicrophoneAccess)
const policyStayOnPluggedModes = Object.values(PolicyStayOnPluggedModes)

export type ConfigProperty = {
  id: string
  property: string
  value: any
  type: string
  options?: string[]
  advanced?: boolean
  error?: boolean
}

export function createApplicationConfig(): ConfigProperty[] {
  return [
    { id: 'packageName', property: 'Package name', value: null, type: 'packageName' },
    { id: 'installType', property: 'Install type', value: null, type: 'dropdown', options: applicationInstallTypes },
    { id: 'defaultPermissionPolicy', property: 'Default permission policy', value: null, type: 'dropdown', options: applicationDefaultPermissionPolicy, advanced: true },
    { id: 'permissionGrants', property: 'Permission grants', value: null, type: 'json', advanced: true },
    { id: 'managedConfiguration', property: 'Managed configuration', value: null, type: 'json', advanced: true },
    { id: 'disabled', property: 'Disabled', value: null, type: 'boolean', advanced: true },
    { id: 'minimumVersionCode', property: 'Minimum version code', value: null, type: 'integer', advanced: true },
    { id: 'delegatedScopes', property: 'Delegated scopes', value: null, type: 'multiselect', options: applicationDelegatedScopes, advanced: true },
    { id: 'managedConfigurationTemplate', property: 'Managed configuration template', value: null, type: 'json', advanced: true },
    { id: 'accessibleTrackIds', property: 'Accessible track ids', value: null, type: 'json', advanced: true },
    { id: 'connectedWorkAndPersonalApp', property: 'Connected work and personal app', value: null, type: 'dropdown', options: applicationConnectedWorkAndPersonalApp, advanced: true },
    { id: 'autoUpdateMode', property: 'Auto update mode', value: null, type: 'dropdown', options: applicationAutoUpdateModes, advanced: true },
    { id: 'extensionConfig', property: 'Extension config', value: null, type: 'json', advanced: true },
    { id: 'alwaysOnVpnLockdownExemption', property: 'Always on vpn lockdown exemption', value: null, type: 'dropdown', options: applicationAlwaysOnVpnLockdownExemption, advanced: true },
    { id: 'workProfileWidgets', property: 'Work profile widgets', value: null, type: 'dropdown', options: applicationWorkProfileWidgets, advanced: true },
  ]
}

export function createSettingsConfig(): ConfigProperty[] {
  return [
    { id: 'statusReportingSettings', property: 'Status reporting settings', value: null, type: 'json' },
    { id: 'playStoreMode', property: 'Play store mode', value: null, type: 'dropdown', options: policyPlayStoreMode },
    { id: 'deviceOwnerLockScreenInfo', property: 'Device owner lock screen info', value: null, type: 'json' },
    { id: 'kioskCustomization', property: 'Kiosk customization', value: null, type: 'json' },
    { id: 'setupActions', property: 'Setup actions', value: null, type: 'json' },
    { id: 'advancedSecurityOverrides', property: 'Advanced security overrides', value: null, type: 'json' },
    { id: 'openNetworkConfiguration', property: 'Open network configuration', value: null, type: 'json' },
    { id: 'screenCaptureDisabled', property: 'Screen capture disabled', value: null, type: 'boolean' },
    { id: 'factoryResetDisabled', property: 'Factory reset disabled', value: null, type: 'boolean' },
    { id: 'cameraAccess', property: 'Camera access', value: null, type: 'dropdown', options: policyCameraAccess },
    { id: 'microphoneAccess', property: 'Microphone access', value: null, type: 'dropdown', options: policyMicrophoneAccess },
    { id: 'networkEscapeHatchEnabled', property: 'Network escape hatch enabled', value: null, type: 'boolean' },
    { id: 'kioskCustomLauncherEnabled', property: 'Kiosk custom launcher enabled', value: null, type: 'boolean' },
    { id: 'setWallpaperDisabled', property: 'Set wallpaper disabled', value: null, type: 'boolean' },
    { id: 'frpAdminEmails', property: 'Frp admin emails', value: null, type: 'json' },
    { id: 'keyguardDisabled', property: 'Keyguard disabled', value: null, type: 'boolean' },
    { id: 'policyEnforcementRules', property: 'Policy enforcement rules', value: null, type: 'json' },
    { id: 'permittedAccessibilityServices', property: 'Permitted accessibility services', value: null, type: 'json' },
    { id: 'installAppsDisabled', property: 'Install apps disabled', value: null, type: 'boolean' },
    { id: 'persistentPreferredActivities', property: 'Persistent preferred activities', value: null, type: 'json' },
    { id: 'mobileNetworksConfigDisabled', property: 'Mobile networks config disabled', value: null, type: 'boolean' },
    { id: 'privateKeySelectionEnabled', property: 'Private key selection enabled', value: null, type: 'boolean' },
    { id: 'outgoingCallsDisabled', property: 'Outgoing calls disabled', value: null, type: 'boolean' },
    { id: 'choosePrivateKeyRules', property: 'Choose private key rules', value: null, type: 'json' },
    { id: 'deviceConnectivityManagement', property: 'Device connectivity management', value: null, type: 'json' },
    { id: 'smsDisabled', property: 'Sms disabled', value: null, type: 'boolean' },
    { id: 'shortSupportMessage', property: 'Short support message', value: null, type: 'json' },
    { id: 'mountPhysicalMediaDisabled', property: 'Mount physical media disabled', value: null, type: 'boolean' },
    { id: 'usageLog', property: 'Usage log', value: null, type: 'json' },
    { id: 'longSupportMessage', property: 'Long support message', value: null, type: 'json' },
    { id: 'permittedInputMethods', property: 'Permitted input methods', value: null, type: 'json' },
    { id: 'autoDateAndTimeZone', property: 'Auto date and time zone', value: null, type: 'dropdown', options: policyAutoDateAndTimeZone },
    { id: 'setUserIconDisabled', property: 'Set user icon disabled', value: null, type: 'boolean' },
    { id: 'removeUserDisabled', property: 'Remove user disabled', value: null, type: 'boolean' },
    { id: 'appAutoUpdatePolicy', property: 'App auto update policy', value: null, type: 'dropdown', options: policyAppAutoUpdatePolicy },
    { id: 'personalUsagePolicies', property: 'Personal usage policies', value: null, type: 'json' },
    { id: 'locationMode', property: 'Location mode', value: null, type: 'dropdown', options: policyLocationMode },
    { id: 'skipFirstUseHintsEnabled', property: 'Skip first use hints enabled', value: null, type: 'boolean' },
    { id: 'recommendedGlobalProxy', property: 'Recommended global proxy', value: null, type: 'json' },
    { id: 'adjustVolumeDisabled', property: 'Adjust volume disabled', value: null, type: 'boolean' },
    { id: 'keyguardDisabledFeatures', property: 'Keyguard disabled features', value: null, type: 'multiselect', options: policyKeyguardDisabledFeatures },
    { id: 'shareLocationDisabled', property: 'Share location disabled', value: null, type: 'boolean' },
    { id: 'credentialsConfigDisabled', property: 'Credentials config disabled', value: null, type: 'boolean' },
    { id: 'networkResetDisabled', property: 'Network reset disabled', value: null, type: 'boolean' },
    { id: 'defaultPermissionPolicy', property: 'Default permission policy', value: null, type: 'dropdown', options: policyDefaultPermissionPolicy },
    { id: 'addUserDisabled', property: 'Add user disabled', value: null, type: 'boolean' },
    { id: 'systemUpdate', property: 'System update', value: null, type: 'json' },
    { id: 'dataRoamingDisabled', property: 'Data roaming disabled', value: null, type: 'boolean' },
    { id: 'permissionGrants', property: 'Permission grants', value: null, type: 'json' },
    { id: 'accountTypesWithManagementDisabled', property: 'Account types with management disabled', value: null, type: 'json' },
    { id: 'androidDevicePolicyTracks', property: 'Android device policy tracks', value: null, type: 'json' },
    { id: 'oncCertificateProviders', property: 'Onc certificate providers', value: null, type: 'json' },
    { id: 'createWindowsDisabled', property: 'Create windows disabled', value: null, type: 'boolean' },
    { id: 'preferentialNetworkService', property: 'Preferential network service', value: null, type: 'dropdown', options: policyPreferentialNetworkService },
    { id: 'stayOnPluggedModes', property: 'Stay on plugged modes', value: null, type: 'multiselect', options: policyStayOnPluggedModes },
    { id: 'bluetoothConfigDisabled', property: 'Bluetooth config disabled', value: null, type: 'boolean' },
    { id: 'outgoingBeamDisabled', property: 'Outgoing beam disabled', value: null, type: 'boolean' },
    { id: 'minimumApiLevel', property: 'Minimum api level', value: null, type: 'integer' },
    { id: 'uninstallAppsDisabled', property: 'Uninstall apps disabled', value: null, type: 'boolean' },
    { id: 'encryptionPolicy', property: 'Encryption policy', value: null, type: 'dropdown', options: policyEncryptionPolicy },
    { id: 'alwaysOnVpnPackage', property: 'Always on vpn package', value: null, type: 'json' },
    { id: 'bluetoothContactSharingDisabled', property: 'Bluetooth contact sharing disabled', value: null, type: 'boolean' },
    { id: 'bluetoothDisabled', property: 'Bluetooth disabled', value: null, type: 'boolean' },
    { id: 'vpnConfigDisabled', property: 'Vpn config disabled', value: null, type: 'boolean' },
    { id: 'passwordPolicies', property: 'Password policies', value: null, type: 'json' },
    { id: 'maximumTimeToLock', property: 'Maximum time to lock', value: null, type: 'string' },
    { id: 'crossProfilePolicies', property: 'Cross profile policies', value: null, type: 'json' },
    { id: 'modifyAccountsDisabled', property: 'Modify accounts disabled', value: null, type: 'boolean' },
    { id: 'cellBroadcastsConfigDisabled', property: 'Cell broadcasts config disabled', value: null, type: 'boolean' },
    { id: 'funDisabled', property: 'Fun disabled', value: null, type: 'boolean' },
  ]
}
