import { MdmApplicationInputSchema, MdmApplicationSchema, PolicySchema } from '@/generated/sdk'
import { sdk } from '@/store/api'
import { InjectionKey, inject, provide, ref } from 'vue'

export function initPolicyConfig() {
  const policyConfig = ref<PolicySchema>(null)

  function setPolicyConfig(config: PolicySchema) {
    policyConfig.value = config
  }

  async function getMdmApplicationsData(input: Partial<MdmApplicationInputSchema>[]): Promise<MdmApplicationSchema[]> {
    const { mdmApplication } = await sdk().mdmApplication({ input })
    return mdmApplication as MdmApplicationSchema[]
  }

  return {
    policyConfig,
    setPolicyConfig,
    getMdmApplicationsData,
  }
}

export const policyConfigKey: InjectionKey<ReturnType<typeof initPolicyConfig>> = Symbol('policyConfig')

export function usePolicyConfig() {
  const policyConfig = inject(policyConfigKey)
  if (!policyConfig) throw new Error('PolicyConfig plugin is not installed')
  return policyConfig
}

export function useProvidePolicyConfig() {
  const policyConfig = initPolicyConfig()
  provide(policyConfigKey, policyConfig)
  return policyConfig
}