<script setup lang="ts">
import { ref, onMounted, watch, computed } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { EnterpriseSchema } from './generated/sdk'

import 'reflect-metadata'
import store from './store'

const router = useRouter()
const route = useRoute()

const showNavBar: any = ref(true)

const menu = [
  ['Policies', '/policies', 'settings'],
  ['Devices', '/devices', 'devices'],
  ['Switch devices', '/switch-devices', 'sync_alt'],
  ['Zero touch', '/zero-touch', 'contactless'],
]

const enterprise = computed<EnterpriseSchema>(() => store.state.app.enterprise)

watch(
  () => router.currentRoute.value.meta,
  async meta => {
    showNavBar.value = meta?.hideNavBar ? !meta?.hideNavBar : true
  },
)

onMounted(() => {
  // Load the google api
  const apiScript = document.createElement('script')
  apiScript.setAttribute('src', 'https://apis.google.com/js/api.js')
  document.head.appendChild(apiScript)
})

function logout() {
  store.commit('setApiKey', null)
  router.push('/login')
}
</script>

<template>
  <NavBar v-if="showNavBar" class="navbar dodo-fonts">
    <template #navbar-header>
      <RouterLink to="/enterprise" class="mdm-l-router-link">
        <NavBarItem :text="enterprise?.enterpriseDisplayName" important>
          <template #icon>
            <img src="./assets/logo.png" class="mdm-l-logo">
          </template>
        </NavBarItem>
      </RouterLink>
    </template>

    <RouterLink
      v-for="item of menu"
      v-slot="link"
      :key="item[1]"
      :to="{ path: item[1] }"
      class="mdm-l-router-link">
      <NavBarItem
        :text="item[0]"
        :icon="item[2]"
        :active="link.isActive" />
    </RouterLink>

    <template #navbar-footer>
      <NavBarItem
        text="Logout"
        icon="logout"
        @click="logout()" />
    </template>

    <template #main>
      <div class="mdm-l-router-container">
        <router-view :key="route.fullPath" />
      </div>
    </template>
  </NavBar>

  <div v-else class="mdm-l-router-container">
    <router-view :key="route.fullPath" />
  </div>

  <PrimeToast />
</template>

<style lang="scss" scoped>
.navbar {
  min-height: 100vh;
}

.mdm-l-router-container {
  height: calc(100vh - 46px);
  overflow-y: scroll;
  overflow-x: hidden;
  width: 100%;
  max-width: 100vw;
  padding: 2rem;
  background-color: #eee;
}

.mdm-l-logo {
  width: 2rem;
}

@media (min-width: 1200px) {
  .mdm-l-router-container {
    height: 100vh;
  }
}
</style>
