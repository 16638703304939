import { Plugin } from 'vue'
import { Router } from 'vue-router'
import { EnterprisePage } from './components'
import { authGuard } from '@/auth-guard'


export const enterprise: Plugin<{ router: Router }> = {
  install: (_, { router }) => {
    // Register enterprise route
    router.addRoute({
      beforeEnter: authGuard,
      name: 'enterprise',
      path: '/enterprise',
      component: EnterprisePage,
    })
  },
}
