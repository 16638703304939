<script setup lang="ts">
import type { Ref } from 'vue'
import { ref, computed, onMounted } from 'vue'
import { useStore } from 'vuex'

import { EnrollmentTokenSchema, MdmPolicySchema } from '@/generated/sdk'
import QrCodeRender from 'qrcode'

const store = useStore()

const props = defineProps<{
  policy?: MdmPolicySchema
}>()

const enrollmentToken: Ref<EnrollmentTokenSchema> = ref(null)
const canvas: Ref<HTMLCanvasElement> = ref(null)
const systemApps = ref(true)
const loading = ref(false)

onMounted(() => {
  renderQr()
})

const qrString = computed(() =>
  enrollmentToken.value?.qrCode
    ? JSON.stringify(JSON.parse(enrollmentToken.value.qrCode), null, 2)
    : null,
)

async function renderQr(): Promise<void> {
  loading.value = true

  enrollmentToken.value = await store.dispatch('createEnrollmentToken', {
    mdmPolicy: props.policy,
    systemApps: systemApps.value,
  })

  await QrCodeRender.toCanvas(
    canvas.value,
    enrollmentToken.value?.qrCode,
    { toSJISFunc: QrCodeRender.toSJIS },
  )

  loading.value = false
}

async function sync(): Promise<void> {
  try {
    await store.dispatch('sync', {
      mdmPolicy: props.policy,
      preserveSystemApps: systemApps.value,
    })
    alert('Synced successfully')
  } catch (e: any) {
    console.log(e.message)
    if (e.message?.includes('No api connection found')) {
      const url = await store.dispatch('tempAuthUrl')
      localStorage.setItem('returnPath', location.pathname)
      location.href = url
    } else {
      alert('Error syncing: ' + (e.message || 'Unknown error'))
      throw e
    }
  }
}
</script>

<template>
  <DodoColumn>
    <DodoRow gap="4">
      <p>Preserve system apps:</p>
      <InputSwitch v-model="systemApps" @change="renderQr()" />
    </DodoRow>
    <DodoButton
      color="info"
      variant="solid"
      @click="sync">
      Sync with Zero-touch
    </DodoButton>
    <DodoRow justify="center">
      <PrimeSkeleton
        :class="{ hidden: !loading }"
        width="436px"
        height="436px" />
      <canvas ref="canvas" :class="{ hidden: loading }"></canvas>
    </DodoRow>
    <DodoRow>
      <pre><code>{{ qrString }}</code></pre>
    </DodoRow>
  </DodoColumn>
</template>

<style scoped>
.hidden {
  display: none;
}
</style>
