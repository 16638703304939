<script setup lang="ts">
import { ref, watch } from 'vue'
import type { ConfigProperty } from './config'

const props = defineProps<{
  configProperties: ConfigProperty[],
  hasErrors: boolean
}>()

const emit = defineEmits(['update:hasErrors'])

const standardProperties = ref<ConfigProperty[]>(props.configProperties.filter(p => !isAdvancedProperty(p)))
const advancedProperties = ref<ConfigProperty[]>(props.configProperties.filter(p => isAdvancedProperty(p)))

const showAdvancedProperties = ref<boolean>(false)

watch(
  props.configProperties,
  (properties) => {
    properties.some(p => p.error === true && p.id !== 'packageName')
      ? emit('update:hasErrors', true)
      : emit('update:hasErrors', false)
  },
)

function checkForJsonError(configProp: ConfigProperty) {
  if (configProp.value != null && configProp.value !== '') {
    try {
      JSON.parse(configProp.value)
      configProp.error = false
    } catch (e) {
      configProp.error = true
    }
  } else {
    configProp.value = null
    configProp.error = false
  }
}

function isAdvancedProperty(property: ConfigProperty) {
  return property.advanced ?? false
}
</script>

<template>
  <div v-for="(properties, index) in [standardProperties, advancedProperties]" :key="index">
    <div v-if="properties === advancedProperties && properties.length > 0" class="advanced-properties">
      <DodoButton rounded @click="showAdvancedProperties = !showAdvancedProperties">
        <DodoIcon :name="showAdvancedProperties ? 'expand_less' : 'expand_more'" /> Advanced properties
      </DodoButton>
    </div>

    <DataTable
      :value="properties"
      :hidden="properties === advancedProperties && !showAdvancedProperties"
      table-style="width: 100%"
    >
      <PrimeColumn
        field="property"
        header="Property"
        style="width: 40%">
        <template #body="{ data: { property } }">
          <div>{{ property }}</div>
        </template>
      </PrimeColumn>

      <PrimeColumn
        field="value"
        header="Value"
        style="width: 60%">
        <template #body="{ data }">
          <DodoRow v-if="data.type == 'packageName'">
            <InputText
              v-model="data.value"
              placeholder="Search package, or insert"
              :class="{ 'p-invalid': data.error ?? false }"
            />

            <PlayStoreFinder v-model:packageName="data.value" />
          </DodoRow>

          <InputText
            v-else-if="data.type == 'string'"
            v-model="data.value"
            placeholder="Insert text"
            :class="{ 'p-invalid': data.error ?? false }"
          />

          <InputNumber
            v-else-if="data.type == 'integer'"
            v-model="data.value"
            placeholder="0" />

          <PrimeTextarea
            v-else-if="data.type == 'json'"
            v-model="data.value"
            placeholder="Insert json"
            rows="7"
            :class="{ 'p-invalid': data.error ?? false }"
            @input="checkForJsonError(data)"
          />

          <PrimeDropdown
            v-else-if="data.type == 'dropdown'"
            v-model="data.value"
            :options="data.options"
            placeholder="Select an option"
            show-clear
          />

          <MultiSelect
            v-else-if="data.type == 'multiselect'"
            v-model="data.value"
            :options="data.options"
            placeholder="Select one or more options"
            :max-selected-labels="1"
          />

          <PrimeCheckbox
            v-else-if="data.type == 'boolean'"
            v-model="data.value"
            :binary="true" />

          <template v-else>
            template not found
          </template>
        </template>
      </PrimeColumn>
    </DataTable>
  </div>
</template>

<style scoped>
input,
.p-dropdown,
.p-multiselect,
.p-inputtextarea,
.p-inputnumber {
  width: 100%;
  resize: vertical;
}

::placeholder {
  color: #d3d3d3;
}

.checkbox-label {
  color: #d3d3d3;
  margin-left: 10px;
}
.p-datatable,
.advanced-properties {
  margin-bottom: 20px;
}

.advanced-properties {
  display: flex;
  justify-content: center;
}
</style>
