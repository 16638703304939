import { MdmPolicySchema, PolicySchema } from '@/generated/sdk'
import { isObject } from 'lodash'

export function formatPolicy(policy?: PolicySchema): string {
  return JSON.stringify(trim(policy), null, 2)
}

export function trim(object?: any) {
  if (isObject(object) && !Array.isArray(object)) {
    const keys = Object.keys(object)
    const result = {}
    for (const key of keys) {
      const trimmed = trim(object[key])
      if (trimmed !== null) {
        result[key] = trimmed
      }
    }
    return result
  } else if (Array.isArray(object)) {
    return object.map(o => trim(o)).filter(o => o !== null)
  } else {
    return object
  }
}

const emptyPolicy: Partial<PolicySchema> = {
  statusReportingSettings: { networkInfoEnabled: true },
  playStoreMode: null,
  deviceOwnerLockScreenInfo: null,
  kioskCustomization: null,
  applications: null,
  setupActions: null,
  advancedSecurityOverrides: null,
  openNetworkConfiguration: null,
  screenCaptureDisabled: null,
  factoryResetDisabled: null,
  cameraAccess: null,
  microphoneAccess: null,
  networkEscapeHatchEnabled: null,
  kioskCustomLauncherEnabled: null,
  setWallpaperDisabled: null,
  frpAdminEmails: null,
  keyguardDisabled: null,
  policyEnforcementRules: null,
  permittedAccessibilityServices: null,
  installAppsDisabled: null,
  persistentPreferredActivities: null,
  mobileNetworksConfigDisabled: null,
  privateKeySelectionEnabled: null,
  outgoingCallsDisabled: null,
  choosePrivateKeyRules: null,
  deviceConnectivityManagement: null,
  smsDisabled: null,
  shortSupportMessage: null,
  mountPhysicalMediaDisabled: null,
  usageLog: null,
  longSupportMessage: null,
  permittedInputMethods: null,
  autoDateAndTimeZone: null,
  setUserIconDisabled: null,
  removeUserDisabled: null,
  appAutoUpdatePolicy: null,
  personalUsagePolicies: null,
  locationMode: null,
  skipFirstUseHintsEnabled: null,
  recommendedGlobalProxy: null,
  adjustVolumeDisabled: null,
  keyguardDisabledFeatures: null,
  shareLocationDisabled: null,
  credentialsConfigDisabled: null,
  networkResetDisabled: null,
  defaultPermissionPolicy: null,
  addUserDisabled: null,
  systemUpdate: null,
  dataRoamingDisabled: null,
  permissionGrants: null,
  accountTypesWithManagementDisabled: null,
  androidDevicePolicyTracks: null,
  oncCertificateProviders: null,
  createWindowsDisabled: null,
  preferentialNetworkService: null,
  stayOnPluggedModes: null,
  bluetoothConfigDisabled: null,
  outgoingBeamDisabled: null,
  minimumApiLevel: null,
  uninstallAppsDisabled: null,
  encryptionPolicy: null,
  alwaysOnVpnPackage: null,
  bluetoothContactSharingDisabled: null,
  bluetoothDisabled: null,
  vpnConfigDisabled: null,
  passwordPolicies: null,
  maximumTimeToLock: null,
  crossProfilePolicies: null,
  modifyAccountsDisabled: null,
  cellBroadcastsConfigDisabled: null,
  funDisabled: null,
}

export function merge(p: MdmPolicySchema, policies: MdmPolicySchema[]): PolicySchema {
  const mergedParent: Partial<PolicySchema> = p.parent?.id
    ? merge(policies.find(parent => parent.id === p.parent?.id), policies)
    : emptyPolicy

  // Merge applications
  const parentApplications = mergedParent.applications ?? []
  const ownApplications = (p.configPolicy.applications ?? []).map(a => ({
    packageName: a.packageName,
    installType: a.installType,
    defaultPermissionPolicy: a.defaultPermissionPolicy ?? null,
    permissionGrants: a.permissionGrants ?? null,
    managedConfiguration: a.managedConfiguration ?? null,
    disabled: a.disabled ?? null,
    minimumVersionCode: a.minimumVersionCode ?? null,
    delegatedScopes: a.delegatedScopes ?? null,
    managedConfigurationTemplate: a.managedConfigurationTemplate ?? null,
    accessibleTrackIds: a.accessibleTrackIds ?? null,
    connectedWorkAndPersonalApp: a.connectedWorkAndPersonalApp ?? null,
    autoUpdateMode: a.autoUpdateMode ?? null,
    extensionConfig: a.extensionConfig ?? null,
    alwaysOnVpnLockdownExemption: a.alwaysOnVpnLockdownExemption ?? null,
    workProfileWidgets: a.workProfileWidgets ?? null,
  }))

  const applications =
    parentApplications.length + ownApplications.length === 0
      ? null
      : parentApplications.concat(ownApplications)

  return {
    id: p.policy.id,
    statusReportingSettings: p.configPolicy.statusReportingSettings ?? mergedParent.statusReportingSettings,
    playStoreMode: p.configPolicy.playStoreMode ?? mergedParent.playStoreMode,
    deviceOwnerLockScreenInfo: p.configPolicy.deviceOwnerLockScreenInfo ?? mergedParent.deviceOwnerLockScreenInfo,
    kioskCustomization: p.configPolicy.kioskCustomization ?? mergedParent.kioskCustomization,
    applications,
    setupActions: p.configPolicy.setupActions ?? mergedParent.setupActions,
    advancedSecurityOverrides: p.configPolicy.advancedSecurityOverrides ?? mergedParent.advancedSecurityOverrides,
    openNetworkConfiguration: p.configPolicy.openNetworkConfiguration ?? mergedParent.openNetworkConfiguration,
    screenCaptureDisabled: p.configPolicy.screenCaptureDisabled ?? mergedParent.screenCaptureDisabled,
    factoryResetDisabled: p.configPolicy.factoryResetDisabled ?? mergedParent.factoryResetDisabled,
    cameraAccess: p.configPolicy.cameraAccess ?? mergedParent.cameraAccess,
    microphoneAccess: p.configPolicy.microphoneAccess ?? mergedParent.microphoneAccess,
    networkEscapeHatchEnabled: p.configPolicy.networkEscapeHatchEnabled ?? mergedParent.networkEscapeHatchEnabled,
    kioskCustomLauncherEnabled: p.configPolicy.kioskCustomLauncherEnabled ?? mergedParent.kioskCustomLauncherEnabled,
    setWallpaperDisabled: p.configPolicy.setWallpaperDisabled ?? mergedParent.setWallpaperDisabled,
    frpAdminEmails: p.configPolicy.frpAdminEmails ?? mergedParent.frpAdminEmails,
    keyguardDisabled: p.configPolicy.keyguardDisabled ?? mergedParent.keyguardDisabled,
    policyEnforcementRules: p.configPolicy.policyEnforcementRules ?? mergedParent.policyEnforcementRules,
    permittedAccessibilityServices: p.configPolicy.permittedAccessibilityServices ?? mergedParent.permittedAccessibilityServices,
    installAppsDisabled: p.configPolicy.installAppsDisabled ?? mergedParent.installAppsDisabled,
    persistentPreferredActivities: p.configPolicy.persistentPreferredActivities ?? mergedParent.persistentPreferredActivities,
    mobileNetworksConfigDisabled: p.configPolicy.mobileNetworksConfigDisabled ?? mergedParent.mobileNetworksConfigDisabled,
    privateKeySelectionEnabled: p.configPolicy.privateKeySelectionEnabled ?? mergedParent.privateKeySelectionEnabled,
    outgoingCallsDisabled: p.configPolicy.outgoingCallsDisabled ?? mergedParent.outgoingCallsDisabled,
    choosePrivateKeyRules: p.configPolicy.choosePrivateKeyRules ?? mergedParent.choosePrivateKeyRules,
    deviceConnectivityManagement: p.configPolicy.deviceConnectivityManagement ?? mergedParent.deviceConnectivityManagement,
    smsDisabled: p.configPolicy.smsDisabled ?? mergedParent.smsDisabled,
    shortSupportMessage: p.configPolicy.shortSupportMessage ?? mergedParent.shortSupportMessage,
    mountPhysicalMediaDisabled: p.configPolicy.mountPhysicalMediaDisabled ?? mergedParent.mountPhysicalMediaDisabled,
    usageLog: p.configPolicy.usageLog ?? mergedParent.usageLog,
    longSupportMessage: p.configPolicy.longSupportMessage ?? mergedParent.longSupportMessage,
    permittedInputMethods: p.configPolicy.permittedInputMethods ?? mergedParent.permittedInputMethods,
    autoDateAndTimeZone: p.configPolicy.autoDateAndTimeZone ?? mergedParent.autoDateAndTimeZone,
    setUserIconDisabled: p.configPolicy.setUserIconDisabled ?? mergedParent.setUserIconDisabled,
    removeUserDisabled: p.configPolicy.removeUserDisabled ?? mergedParent.removeUserDisabled,
    appAutoUpdatePolicy: p.configPolicy.appAutoUpdatePolicy ?? mergedParent.appAutoUpdatePolicy,
    personalUsagePolicies: p.configPolicy.personalUsagePolicies ?? mergedParent.personalUsagePolicies,
    locationMode: p.configPolicy.locationMode ?? mergedParent.locationMode,
    skipFirstUseHintsEnabled: p.configPolicy.skipFirstUseHintsEnabled ?? mergedParent.skipFirstUseHintsEnabled,
    recommendedGlobalProxy: p.configPolicy.recommendedGlobalProxy ?? mergedParent.recommendedGlobalProxy,
    adjustVolumeDisabled: p.configPolicy.adjustVolumeDisabled ?? mergedParent.adjustVolumeDisabled,
    keyguardDisabledFeatures: p.configPolicy.keyguardDisabledFeatures ?? mergedParent.keyguardDisabledFeatures,
    shareLocationDisabled: p.configPolicy.shareLocationDisabled ?? mergedParent.shareLocationDisabled,
    credentialsConfigDisabled: p.configPolicy.credentialsConfigDisabled ?? mergedParent.credentialsConfigDisabled,
    networkResetDisabled: p.configPolicy.networkResetDisabled ?? mergedParent.networkResetDisabled,
    defaultPermissionPolicy: p.configPolicy.defaultPermissionPolicy ?? mergedParent.defaultPermissionPolicy,
    addUserDisabled: p.configPolicy.addUserDisabled ?? mergedParent.addUserDisabled,
    systemUpdate: p.configPolicy.systemUpdate ?? mergedParent.systemUpdate,
    dataRoamingDisabled: p.configPolicy.dataRoamingDisabled ?? mergedParent.dataRoamingDisabled,
    permissionGrants: p.configPolicy.permissionGrants ?? mergedParent.permissionGrants,
    accountTypesWithManagementDisabled: p.configPolicy.accountTypesWithManagementDisabled ?? mergedParent.accountTypesWithManagementDisabled,
    androidDevicePolicyTracks: p.configPolicy.androidDevicePolicyTracks ?? mergedParent.androidDevicePolicyTracks,
    oncCertificateProviders: p.configPolicy.oncCertificateProviders ?? mergedParent.oncCertificateProviders,
    createWindowsDisabled: p.configPolicy.createWindowsDisabled ?? mergedParent.createWindowsDisabled,
    preferentialNetworkService: p.configPolicy.preferentialNetworkService ?? mergedParent.preferentialNetworkService,
    stayOnPluggedModes: p.configPolicy.stayOnPluggedModes ?? mergedParent.stayOnPluggedModes,
    bluetoothConfigDisabled: p.configPolicy.bluetoothConfigDisabled ?? mergedParent.bluetoothConfigDisabled,
    outgoingBeamDisabled: p.configPolicy.outgoingBeamDisabled ?? mergedParent.outgoingBeamDisabled,
    minimumApiLevel: p.configPolicy.minimumApiLevel ?? mergedParent.minimumApiLevel,
    uninstallAppsDisabled: p.configPolicy.uninstallAppsDisabled ?? mergedParent.uninstallAppsDisabled,
    encryptionPolicy: p.configPolicy.encryptionPolicy ?? mergedParent.encryptionPolicy,
    alwaysOnVpnPackage: p.configPolicy.alwaysOnVpnPackage ?? mergedParent.alwaysOnVpnPackage,
    bluetoothContactSharingDisabled: p.configPolicy.bluetoothContactSharingDisabled ?? mergedParent.bluetoothContactSharingDisabled,
    bluetoothDisabled: p.configPolicy.bluetoothDisabled ?? mergedParent.bluetoothDisabled,
    vpnConfigDisabled: p.configPolicy.vpnConfigDisabled ?? mergedParent.vpnConfigDisabled,
    passwordPolicies: p.configPolicy.passwordPolicies ?? mergedParent.passwordPolicies,
    maximumTimeToLock: p.configPolicy.maximumTimeToLock ?? mergedParent.maximumTimeToLock,
    crossProfilePolicies: p.configPolicy.crossProfilePolicies ?? mergedParent.crossProfilePolicies,
    modifyAccountsDisabled: p.configPolicy.modifyAccountsDisabled ?? mergedParent.modifyAccountsDisabled,
    cellBroadcastsConfigDisabled: p.configPolicy.cellBroadcastsConfigDisabled ?? mergedParent.cellBroadcastsConfigDisabled,
    funDisabled: p.configPolicy.funDisabled ?? mergedParent.funDisabled,
  } as PolicySchema
}

export async function getPoliciesToUpdate(
  remotePolicies: PolicySchema[],
  localPolicies: PolicySchema[],
): Promise<PolicySchema[]> {
  const policiesToUpdate: PolicySchema[] = []

  // Sort applications, so the diff makes sense
  for (const p of [...remotePolicies, ...localPolicies]) {
    (p.applications ?? []).sort((a, b) =>
      a.packageName.localeCompare(b.packageName),
    )
  }

  // Search for diffs by serializing to JSON
  for (const localPolicy of localPolicies) {
    const remotePolicy = remotePolicies.find(p => p.id === localPolicy.id)

    if (!remotePolicy) {
      console.warn('No policy found for: ' + localPolicy.name)
    }

    const local = formatPolicy(localPolicy)
    const remote = formatPolicy(remotePolicy)

    if (local !== remote) {
      policiesToUpdate.push(localPolicy)

      console.log(`------ Syncing: ${localPolicy.id}`)

      const localLines = local.split('\n')
      const remoteLines = remote.split('\n')

      for (let i = 0; i < localLines.length; i++) {
        const l = localLines[i]
        const r = remoteLines[i]

        if (l !== r) {
          console.log(`local  : ${l}`)
          console.log(`remote : ${r}`)
        }
      }

      console.log('------')
    }
  }

  return policiesToUpdate
}