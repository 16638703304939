<script setup lang="ts">
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import { computed, onMounted } from 'vue'

import { MdmPolicySchema } from '@/generated/sdk'

import { useProvidePolicyConfig } from '..'
const { setPolicyConfig } = useProvidePolicyConfig()

const store = useStore()
const route = useRoute()
const policies = computed<MdmPolicySchema[]>(() => store.state.policies.mdmPolicies)
const currentPolicy = computed<MdmPolicySchema>(() => store.state.policies.currentPolicy)

onMounted(async () => {
  const policyId = route.params.id
  const currentPolicy = policies.value.find(m => m.id === policyId)
  if (currentPolicy) {
    store.commit('setCurrentPolicy', currentPolicy)
    await setPolicyConfig(currentPolicy.configPolicy)
  }
})
</script>

<template>
  <DodoColumn>
    <DodoRow>
      <h1>Policy: {{ currentPolicy?.name || '' }}</h1>
    </DodoRow>

    <DodoTabs
      :tabs="[
        { name: 'Configuration', slot: 'configuration' },
        { name: 'Enrollment', slot: 'enrollment' },
      ]"
    >
      <template #configuration>
        <PolicyConfiguration />
      </template>
      <template #enrollment>
        <DodoCard>
          <QrCode :policy="currentPolicy" />
        </DodoCard>
      </template>
    </DodoTabs>
  </DodoColumn>
</template>

<style lang="scss" scoped>
.mdm-l-panel {
  padding: 1rem;
  overflow-x: scroll;
}
</style>
