import { createStore } from 'vuex'
import app from './app'
import policies from './policies'
import tokens from './tokens'
import devices from './devices'

export default createStore({
  modules: {
    app,
    policies,
    tokens,
    devices,
  },
})
