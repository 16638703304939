<script setup lang="ts">
import { ref, computed, watch } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { useToast } from 'primevue/usetoast'
const toast = useToast()

import { MdmPolicySchema, PolicySchema } from '@/generated/sdk'
import { merge, formatPolicy, getPoliciesToUpdate } from './policy.utils'
import { usePolicyConfig } from '..'

const store = useStore()
const router = useRouter()

const mdmPolicies = computed<MdmPolicySchema[]>(() => store.state.policies.mdmPolicies)
const currentMdmPolicy = computed<MdmPolicySchema>(() => store.state.policies.currentPolicy)
const parents = computed(() => getParents(currentMdmPolicy.value).reverse())

const { policyConfig } = usePolicyConfig()

const possibleParentPolicies = computed<MdmPolicySchema[]>(() =>
  mdmPolicies.value.filter(
    mdmPolicy =>
      mdmPolicy.id !== currentMdmPolicy.value?.id &&
      !getParents(mdmPolicy).find(parent => parent.id === currentMdmPolicy.value?.id),
  ),
)

const parentId = ref<string>(currentMdmPolicy.value?.parent?.id || '')

watch(currentMdmPolicy, () => {
  parentId.value = currentMdmPolicy.value?.parent?.id
})

function getParents(mdmPolicy: MdmPolicySchema): MdmPolicySchema[] {
  const parent = getParent(mdmPolicy)
  return parent ? [parent].concat(getParents(parent)) : []
}

function getParent(mdmPolicy: MdmPolicySchema): MdmPolicySchema {
  return mdmPolicies.value.find(m => m.id === mdmPolicy?.parent?.id)
}

async function tryUpdatePolicy() {
  try {
    await updatePolicy()
  } catch (error) {
    toast.add({
      severity: 'error',
      summary: 'Update failed',
      detail: 'Something went wrong while updating the policies',
      life: 3000,
    })
  }
}

async function updatePolicy() {
  // Get the new config and connect it to the cloned policy
  const newConfigPolicy: PolicySchema = JSON.parse(formatPolicy(policyConfig.value))

  const clonedMdmPolicies: MdmPolicySchema[] = JSON.parse(JSON.stringify(mdmPolicies.value))
  const clonedMdmPolicy = clonedMdmPolicies.find(p => p.id === currentMdmPolicy.value.id)

  clonedMdmPolicy.configPolicy = newConfigPolicy

  clonedMdmPolicy.parent = parentId.value ? ({ id: parentId.value } as MdmPolicySchema) : undefined

  // Re-generate all policies with the new config
  for (const mdmPolicy of clonedMdmPolicies) {
    mdmPolicy.policy = merge(mdmPolicy, clonedMdmPolicies)
  }

  // Get all policies to update
  const policiesToUpdate = await getPoliciesToUpdate(
    mdmPolicies.value.map(p => p.policy),
    clonedMdmPolicies.map(p => p.policy),
  )

  if (policiesToUpdate.length === 0) {
    alert('No policies to update')
  } else {
    if (confirm(`Update ${policiesToUpdate.length} policies? (This will only succeed if the config policy is valid)`)) {
      // First update our policy (may still fail validation)
      await store.dispatch('updatePolicies', [clonedMdmPolicy.configPolicy].concat(policiesToUpdate))
    }
  }

  // Always update the parent
  await store.dispatch('updateMdmPolicy', clonedMdmPolicy)
  router.go(0)
}
</script>

<template>
  <PrimePanel
    header="Parent policies"
    toggleable
    class="panel">
    <DodoColumn>
      <DodoRow v-if="parents.length" gap="0">
        <template v-for="(parent, index) of parents" :key="parent.id">
          <DodoChip :color="index === parents.length - 1 ? 'success' : 'foreground'">
            <template v-if="index == 0">Base: </template>
            <template v-else>Parent: </template>
            <router-link :to="`/policies/${parent.id}`">
              {{ parent.name }}
            </router-link>
          </DodoChip>
          <DodoIcon v-if="index < parents.length - 1" name="chevron_right" />
        </template>
      </DodoRow>
      <DodoRow>
        <PrimeDropdown
          v-model="parentId"
          class="dropdown"
          :options="possibleParentPolicies"
          :loading="possibleParentPolicies == null"
          :show-clear="true"
          option-value="id"
          option-label="name"
          placeholder="Select a parent policy"
        />
        <DodoButton
          variant="solid"
          color="success"
          @click="updatePolicy">
          <DodoIcon name="save" />
          Save
        </DodoButton>
      </DodoRow>
    </DodoColumn>
  </PrimePanel>
  <DodoCard>
    <PolicyConfigurationSettings />
    <DodoRow justify="end">
      <DodoButton
        variant="solid"
        color="success"
        @click="tryUpdatePolicy">
        <DodoIcon name="save" />
        Save configuration
      </DodoButton>
    </DodoRow>
  </DodoCard>

  <PrimePanel
    header="Managed configuration (BETA)"
    toggleable
    collapsed
    class="panel">
    <PlayStore />
  </PrimePanel>
</template>

<style scoped>
.panel {
  width: 100%;
}

.dropdown {
  min-width: 200px;
}

.result {
  background-color: #f8f9fa;
  border: 1px solid #dee2e6;
  border-radius: 5px;
  padding: 10px;
  margin: 0;
}

.p-inputtextarea {
  width: 100%;
  resize: vertical;
}
</style>
