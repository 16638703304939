import { Plugin } from 'vue'
import { Router } from 'vue-router'
import { LoginPage } from './components'

export const login: Plugin<{ router: Router }> = {
  install: (_, { router }) => {
    // Register login routes
    router.addRoute({
      name: 'login',
      path: '/login',
      component: LoginPage,
      meta: { hideNavBar: true },
    })
  },
}
