import { Plugin } from 'vue'
import { Router } from 'vue-router'
import { DevicesPage } from './components'
import { DeviceDetailsPage } from './components'
import { SwitchDevices } from './components'
import { authGuard } from '@/auth-guard'

export const devices: Plugin<{ router: Router }> = {
  install: (_, { router }) => {
    // Register devices route
    router.addRoute({
      beforeEnter: authGuard,
      name: 'devices',
      path: '/devices',
      component: DevicesPage,
    })
    router.addRoute({
      beforeEnter: authGuard,
      name: 'switchDevices',
      path: '/switch-devices',
      component: SwitchDevices,
    })
    router.addRoute({
      beforeEnter: authGuard,
      name: 'deviceDetails',
      path: '/devices/:id',
      component: DeviceDetailsPage,
    })
  },
}
