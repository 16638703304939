<script setup lang="ts">
import { onMounted } from 'vue'
import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'

const store = useStore()
const router = useRouter()
const route = useRoute()

onMounted(async () => {
  await store.dispatch('tempSetToken', route.query.code)
  router.push(localStorage.getItem('returnPath') || '/')
})
</script>

<template>
  <div>
    Loading Google credentials...
  </div>
</template>
