import { Plugin } from 'vue'
import { Router } from 'vue-router'
import { ZeroTouch } from './components'
import { authGuard } from '@/auth-guard'


export const zeroTouch: Plugin<{ router: Router }> = {
  install: (_, { router }) => {
    // Register zeroTouch route
    router.addRoute({
      beforeEnter: authGuard,
      name: 'zeroTouch',
      path: '/zero-touch',
      component: ZeroTouch,
    })
  },
}
