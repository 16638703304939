<script setup lang="ts">
import { computed, onMounted, ref } from 'vue'
import { useStore } from 'vuex'

declare const gapi: any
const store = useStore()

const container = ref<HTMLElement | null>(null)

const isHttps = computed(() => location.protocol === 'https:')

onMounted(async () => {
  if (!isHttps.value) return

  const { value } = await store.dispatch('createWebToken')
  const url = `https://enterprise.google.com/android/zero-touch/embedded/companyhome?token=${value}&dpcId=com.google.android.apps.work.clouddpc`

  const div = document.createElement('div')

  gapi.load('gapi.iframes', () => {
    const options = {
      url,
      where: div,
      attributes: {
        style: 'width: 1000px; height:1000px',
        scrolling: 'yes',
      },
    }
    gapi.iframes.getContext().openChild(options)
  })

  container.value?.appendChild(div)
})
</script>

<template>
  <div v-if="isHttps" ref="container"></div>
  <div v-else>Https (SSL) is required to load Zero-touch portal</div>
</template>
