import { Plugin } from 'vue'
import { Router } from 'vue-router'
import { GoogleRedirect } from './components'
import { authGuard } from '@/auth-guard'


export const googleRedirect: Plugin<{ router: Router }> = {
  install: (_, { router }) => {
    // Register googleRedirect route
    router.addRoute({
      beforeEnter: authGuard,
      name: 'google-redirect',
      path: '/google-redirect',
      component: GoogleRedirect,
    })
  },
}
