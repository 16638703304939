<script setup lang="ts">
import { MdmDeviceSchema, MdmPolicySchema, MdmDeviceInputSchema } from '@/generated/sdk'

import * as Papa from 'papaparse'
import { computed, ref } from 'vue'
import { useStore } from 'vuex'

const store  = useStore()

// The raw csv row
interface CSVEntry {
  rowNumber: number
  type: 'imei' | 'serial'
  reference: string
  policy: string
}

// Possible result states after processing rows
enum CSVPreviewState {
  DeviceNotFound,
  MultipleDevicesFound,
  PolicyNotFound,
  DevicePolicyNotFound,
  IncorrectPolicy,
  InvalidRow,
  OK,
}

// Result after processing the rows (before actual execution)
interface CSVPreviewEntry {
  rowNumber: number
  entry: CSVEntry
  message: string
  matchedDevices?: MdmDeviceSchema[]
  matchedPolicy?: MdmPolicySchema
  devicePolicy?: MdmPolicySchema
  state: CSVPreviewState
}

const previewEntries =  ref<CSVPreviewEntry[]>(null)
const error =  ref<string>(null)

const mdmPolicies = computed<MdmPolicySchema[]>( ()=> store.state.policies.mdmPolicies)

const mdmDevices = computed<MdmDeviceSchema[]>( ()=> store.state.devices.mdmDevices)

function upload({ files }: { files: File[] }) {
  for (const file of files) {
    Papa.parse<CSVEntry>(file, {
      header: true,
      error: (err, file) =>
      {
        console.error(err)
        error.value = err.message
      },
      complete: (parsed) =>
      {
        handleCSV(parsed.data)
          .then((res) => previewEntries.value = res)
          .catch((e) => console.error('Error handling CSV:', e))
      },
    })
  }
}

async function handleCSV(entries: CSVEntry[]): Promise<CSVPreviewEntry[]> {
  
  const search = entries
    .filter(e => isValidEntry(e))
    .map(e =>
      ({ 
        [e.type]: e.reference, 
        enterpriseId: store.state.app.enterprise.id,
      }),
    )

  await store.dispatch('loadMdmDevices', { 
    input: search,
    queryArgs: { 
      size: 10000, 
      page: 1,
    },
  })

  return entries.map((e, index) => toPreview(e, index))
}

function toPreview(entry: CSVEntry, index: number): CSVPreviewEntry {
  const matchedPolicy = mdmPolicies.value.find(p => p.name === entry.policy || p.id === entry.policy)
  const rowNumber = index + 1

  if (!isValidEntry(entry)){
    return {
      rowNumber,
      entry,
      state: CSVPreviewState.InvalidRow,
      message: 'Invalid row',
    }
  }

  if (!matchedPolicy) {
    return {
      rowNumber,
      entry,
      state: CSVPreviewState.PolicyNotFound,
      message: `Policy not found (${entry.policy})`,
    }
  }
  
  const matchedDevices = mdmDevices.value.filter(d =>
    d[entry.type] === entry.reference,
  )

  if (matchedDevices.length === 0) {
    return {
      rowNumber,
      entry,
      matchedDevices,
      matchedPolicy,
      state: CSVPreviewState.DeviceNotFound,
      message: `Device not found (${entry.type}: "${entry.reference}")`,
    }
  }

  if (matchedDevices.length > 1) {
    return {
      rowNumber,
      entry,
      matchedDevices,
      matchedPolicy,
      state: CSVPreviewState.MultipleDevicesFound,
      message: `Multiple devices found (${matchedDevices.map(d => formatId(d.googleId)).join(' - ')})`,
    }
  }

  const devicePolicy = mdmPolicies.value.find(p => p.googleId === matchedDevices[0].policyName)

  if (!devicePolicy) {
    return {
      rowNumber,
      entry,
      matchedDevices,
      matchedPolicy,
      state: CSVPreviewState.DevicePolicyNotFound,
      message: `Device policy not found (${matchedDevices[0].policyName})`,
    }
  }

  if (devicePolicy.id !== matchedPolicy.id) {
    return {
      rowNumber,
      entry,
      matchedDevices,
      matchedPolicy,
      devicePolicy,
      state: CSVPreviewState.IncorrectPolicy,
      message: `Incorrect policy (expected: "${matchedPolicy.name}" got: "${devicePolicy.name}")`,
    }
  }

  return {
    rowNumber,
    entry,
    matchedDevices,
    matchedPolicy,
    devicePolicy,
    state: CSVPreviewState.OK,
    message:'OK',
  }
}

async function continueProcessing(){
  const toUpdate = previewEntries.value
    .filter(p => p.state === CSVPreviewState.IncorrectPolicy)
    .map(t => ({
      id: t.matchedDevices[0].id,
      googleId: t.matchedDevices[0].googleId,
      policyName: t.matchedPolicy.googleId,
    } as MdmDeviceSchema))

  if(toUpdate.length === 0){
    window.alert('No devices will be updated directly')
  } else if (window.confirm(`Are you sure you want to directly update the policy of ${toUpdate.length} devices?`)) {
    await store.dispatch('updateMdmDevices', toUpdate)
  } else {
    return
  }

  const toInsert = previewEntries.value
    .filter(p => p.state === CSVPreviewState.DeviceNotFound)
    .map(t => ({
      enterpriseId: store.state.app.enterprise.id,
      expectedPolicyName: t.matchedPolicy.googleId,
      imei: t.entry.type === 'imei' ? t.entry.reference : undefined,
      serial: t.entry.type === 'serial' ? t.entry.reference : undefined,
    } as MdmDeviceInputSchema))


  if(toInsert.length === 0){
    window.alert('No devices will preloaded')
  } else if (window.confirm(`Are you sure you want to preload the policy of ${toInsert.length} devices?`)) {
    await store.dispatch('createMdmDevices', toInsert)
  } else {
    return
  }
    
  previewEntries.value = null
}

function rowClass(data: CSVPreviewEntry) {
  return {
    [CSVPreviewState.OK] : 'mdm-success',
    [CSVPreviewState.IncorrectPolicy] : 'mdm-warn',
  }[data.state] || 'mdm-danger'
}

function formatId(id: string): string {
  return id.split('/')[3]
}

function isValidEntry(e: CSVEntry) {
  return ['imei', 'serial'].includes(e.type.toLowerCase()) && e.reference
}

</script>

<template>
  <div>
    <h1>Bulk policy edit</h1>
    <br>
    <h3>Download <a href="/bulk.csv">sample file</a>.</h3>
    <br>
    <FileUpload
      :custom-upload="true"
      accept="text/csv"
      @uploader="upload"
    />
    <br>
    <DataTable 
      v-if="previewEntries"
      :value="previewEntries" 
      responsive-layout="scroll" 
      :paginator="true"
      :rows="10"
      paginator-template="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
      :rows-per-page-options="[10,25,100]"
      current-page-report-template="Showing {first} to {last} of {totalRecords}"
      :row-class="rowClass"
    >
      <PrimeColumn
        field="rowNumber"
        header="Row"
        :sortable="true" />
      <PrimeColumn
        field="entry.type"
        header="Type"
        :sortable="true" />
      <PrimeColumn
        field="entry.reference"
        header="Reference"
        :sortable="true" />
      <PrimeColumn
        field="message"
        header="Result"
        :sortable="true" />
    </DataTable>
    <br>
    <DodoRow v-if="previewEntries" gap="s">
      <DodoButton
        color="info"
        variant="solid"
        @click="continueProcessing">
        Continue
      </DodoButton>
      <DodoButton color="info" @click="() => previewEntries = null">
        Cancel
      </DodoButton>
    </DodoRow>
  </div>
</template>
