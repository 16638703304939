import { sdk } from './api'
import { EnrollmentTokenSchema, MdmPolicySchema } from '@/generated/sdk'
import { Module } from 'vuex'

export interface TokenCreateOptions {
  systemApps: boolean
  mdmPolicy?: MdmPolicySchema
}
export interface TokensState {
}

export default {
  state: {
  },

  getters: {
  },

  mutations: {
  },

  actions: {
    async createWebToken({ rootState }) {
      const { createWebToken: data } = await sdk().createWebToken({
        enterpriseId: rootState.app.enterprise.id,
        parentFrameUrl: location.href,
      })
      return data
    },
    async tempAuthUrl({ rootState }) {
      const { tempAuthUrl: data } = await sdk().tempAuthUrl({
        redirectUri: location.origin + '/google-redirect',
      })
      return data
    },
    async tempSetToken({ rootState }, code: string) {
      await sdk().tempSetToken({ code })
    },
    async sync({ rootState }, options: { mdmPolicy: MdmPolicySchema, preserveSystemApps: boolean }) {
      await sdk().sync({
        mdmPolicyId: options.mdmPolicy.id,
        preserveSystemApps: options.preserveSystemApps,
      })
    },
    async createEnrollmentToken({ rootState }, options: TokenCreateOptions) {
      let data: EnrollmentTokenSchema
      if (options.mdmPolicy) {
        const r = await sdk().createEnrollmentTokenForPolicy({
          policyId: options.mdmPolicy.googleId,
        })
        data = r.createEnrollmentToken
      } else {
        const r = await sdk().createEnrollmentTokenForEnterprise({
          enterpriseId: rootState.app.enterprise.id,
        })
        data = r.createEnrollmentToken
      }
      // Make sure to add additional QR properties
      const qr = JSON.parse(data.qrCode)
      if (options.systemApps) {
        qr['android.app.extra.PROVISIONING_LEAVE_ALL_SYSTEM_APPS_ENABLED'] = true
      }
      qr['android.app.extra.PROVISIONING_USE_MOBILE_DATA'] = true
      qr['android.app.extra.PROVISIONING_LOCALE'] = 'en_US'
      data.qrCode = JSON.stringify(qr)
      return data
    },
  },

} as Module<TokensState, any>
