import { MdmPolicyInputSchema, MdmPolicySchema, PolicyInputSchema } from '@/generated/sdk'
import { Module } from 'vuex'
import { sdk } from './api'

export interface PoliciesState {
  currentPolicy: MdmPolicySchema
  mdmPolicies: MdmPolicySchema[]
}

export default {
  state: {
    currentPolicy: null,
    mdmPolicies: null,
  },

  getters: {
    currentPolicy(state) {
      return state.currentPolicy
    },
    mdmPolicies(state) {
      return state.mdmPolicies
    },
  },

  mutations: {
    setCurrentPolicy(state, currentPolicy: MdmPolicySchema) {
      state.currentPolicy = currentPolicy
    },
    setMdmPolicies(state, mdmPolicies: MdmPolicySchema[]) {
      state.mdmPolicies = mdmPolicies
    },
  },

  actions: {
    async loadMdmPolicies({ commit, rootState }) {
      const { mdmPolicy: mdmPolicies } = await sdk().mdmPolicies({
        // input: {
        //   enterpriseId: rootState.app.enterprise.id,
        // },
        queryArgs: {
          size: 1000,
        },
      })
      commit('setMdmPolicies', mdmPolicies)
    },

    async createPolicy({ commit, state, dispatch, rootState }, newPolicyName: string) {
      const policy = await sdk().createPolicy({
        input: {
          statusReportingSettings: { // Only setting always on
            networkInfoEnabled: true,
          },
          enterprise: { id: rootState.app.enterprise.id },
        },
      })

      const configPolicy = await sdk().createPolicy({
        input: {
          enterprise: { id: rootState.app.enterprise.id },
        },
      })

      await sdk().createMdmPolicy({
        input: {
          enterpriseId: rootState.app.enterprise.id,
          googleId: policy.createPolicy.id,
          configGoogleId: configPolicy.createPolicy.id,
          name: newPolicyName,
        },
      })
      await dispatch('loadMdmPolicies')
    },

    async updatePolicies({ commit, state, dispatch }, policies: PolicyInputSchema[]) {
      for (const policy of policies) {
        const res = await sdk().updatePolicy({ input: policy })
        if ((res as any).errors) return
      }
    },

    async updateMdmPolicy({ commit, state, dispatch }, policy: MdmPolicyInputSchema) {
      const clone = JSON.parse(JSON.stringify(policy))
      delete clone.policy
      delete clone.configPolicy
      await sdk().updateMdmPolicy({ input: clone })
      await dispatch('loadMdmPolicies')
    },
  },
} as Module<PoliciesState, any>
