import type { Plugin } from 'vue'
import type { Router } from 'vue-router'

import { authGuard } from '@/auth-guard'

import { PoliciesPage } from './components'
import { PolicyEditPage } from './components'
import { PolicyEditBulkPage } from './components'

export const policies: Plugin<{ router: Router }> = {
  install: (app, { router }) => {
    // Register policies route
    router.addRoute({
      beforeEnter: authGuard,
      name: 'policies',
      path: '/policies',
      component: PoliciesPage,
    })
    router.addRoute({
      beforeEnter: authGuard,
      name: 'policyEdit',
      path: '/policies/:id',
      component: PolicyEditPage,
    })
    router.addRoute({
      beforeEnter: authGuard,
      name: 'policyEditBulk',
      path: '/policies/bulk',
      component: PolicyEditBulkPage,
    })
  },
}
