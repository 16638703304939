<script setup lang="ts">
import { ref, computed } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

import { MdmPolicySchema, StatsQuery } from '@/generated/sdk'

const store = useStore()
const router = useRouter()

const sortField = ref('name')
const sortOrder = ref(1)
const filter = ref('')
const showDialog = ref(false)
const creationLoading = ref(false)
const newPolicyName = ref('')

const stats = computed<StatsQuery>(() => store.state.app.stats)

const mdmPolicies = computed<MdmPolicySchema[]>(() =>
  store.state.policies.mdmPolicies
    ?.filter(p => {
      if (!filter.value.trim()) return true
      return p.name.toLowerCase().includes(filter.value.trim().toLowerCase())
    })
    .sort((a, b) => {
      let result = 0
      if (sortField.value === 'nApps') {
        result = (a.policy?.applications?.length || 0) - (b.policy?.applications?.length || 0)
      } else {
        // Sort by name is default
        result = a.name.localeCompare(b.name)
      }
      return sortOrder.value * result
    }),
)

function activeMdmDevicesByPolicy(policy: MdmPolicySchema) {
  return stats.value.countMdmDeviceByPolicyByDate.find(c => c.group === policy.googleId)?.count || 0
}

function totalMdmDevicesByPolicy(policy: MdmPolicySchema) {
  return stats.value.countPerPolicy.find(c => c.group === policy.googleId)?.count || 0
}

function rowClick(event) {
  router.push(`/policies/${event.data.id}`)
}

function sort(event) {
  sortField.value = event.sortField
  sortOrder.value = event.sortOrder
}

function getParentName(policy: MdmPolicySchema) {
  return mdmPolicies.value.find(p => policy.parent?.id === p.id)?.name || '-'
}

async function createPolicy() {
  showDialog.value = false
  creationLoading.value = true

  try {
    await store.dispatch('createPolicy', newPolicyName.value)
    newPolicyName.value = ''
  } finally {
    creationLoading.value = false
  }
}
</script>

<template>
  <DodoColumn>
    <DodoRow justify="between">
      <h1 class="header-padding">Policies</h1>
      <DodoButton
        color="success"
        variant="solid"
        :disabled="creationLoading"
        @click="showDialog = true">
        <DodoIcon name="add" />
        Create new policy
      </DodoButton>
    </DodoRow>
    <DodoRow>
      <DataTable
        :value="mdmPolicies"
        responsive-layout="scroll"
        :paginator="true"
        :rows="25"
        paginator-template="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
        :rows-per-page-options="[10, 25, 100]"
        current-page-report-template="Showing {first} to {last} of {totalRecords}"
        selection-mode="single"
        style="width: 100%"
        @sort="sort"
        @row-click="rowClick"
      >
        <template #header>
          <DodoRow justify="end">
            <span class="p-input-icon-left">
              <i class="pi pi-search"></i>
              <InputText v-model="filter" placeholder="Search" />
            </span>
          </DodoRow>
        </template>
        <PrimeColumn
          field="name"
          header="Name"
          :sortable="true">
          <template #body="{data}">
            {{ data.name }}
          </template>
        </PrimeColumn>
        <PrimeColumn
          field="name"
          header="Parent"
          :sortable="true">
          <template #body="{data}">
            {{ getParentName(data) }}
          </template>
        </PrimeColumn>
        <PrimeColumn
          field="nApps"
          header="Number of apps"
          :sortable="true">
          <template #body="{data}">
            {{ data.policy?.applications?.length || 0 }}
          </template>
        </PrimeColumn>
        <PrimeColumn field="active" header="Active this month">
          <template #body="{data}">
            {{ activeMdmDevicesByPolicy(data) }}
          </template>
        </PrimeColumn>
        <PrimeColumn field="total" header="Total">
          <template #body="{data}">
            {{ totalMdmDevicesByPolicy(data) }}
          </template>
        </PrimeColumn>
      </DataTable>
    </DodoRow>
  </DodoColumn>

  <DodoDialog :active="showDialog">
    <h3>Create new policy</h3>
    <p>Name</p>
    <InputText v-model="newPolicyName" placeholder="Name" />
    <template #controls>
      <DodoButton variant="text" @click="showDialog = false">
        Cancel
      </DodoButton>
      <DodoButton
        variant="solid"
        color="info"
        @click="createPolicy">
        Create
      </DodoButton>
    </template>
  </DodoDialog>
</template>
