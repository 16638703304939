<script setup lang="ts">
import { ref } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

const store = useStore()
const router = useRouter()

const apiKey = ref('')

function login(): void {
  store.commit('setApiKey', apiKey.value)
  router.push('/policies')
}
</script>

<template>
  <div class="mdm-l-login-container">
    <h1>Login</h1>
    <p>Please provide an API Key</p>
    <br>
    <InputText v-model="apiKey" type="text" />
    <br>
    <br>
    <DodoButton @click="login()">
      Login
    </DodoButton>
  </div>
</template>

<style lang="scss" scoped>
.mdm-l-login-container {
  margin: 100px auto;
}
</style>
