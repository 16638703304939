<script setup lang="ts">
import { computed, onMounted } from 'vue'
import { useStore } from 'vuex'
import { formatStringDate as formatDate } from '@/utils'

import { SwitchDeviceArgs, SwitchDeviceSchema } from '@/generated/sdk'

const store = useStore()

const switches = computed<SwitchDeviceSchema[]>(() => store.state.devices.switches)

onMounted(async () => await loadSwitches({ page: 1, size: 10 }))

async function onPage(event) {
  await loadSwitches({ page: event.page + 1, size: event.rows })
}

async function loadSwitches(queryArgs: SwitchDeviceArgs): Promise<void> {
  store.dispatch('loadSwitches', queryArgs)
}

function formatId(id: string): string {
  return id.split('/')[3]
}

function formatPolicyName(id: string): string {
  return store.state.policies.mdmPolicies.find(m => m.googleId === id).name
}
</script>

<template>
  <DodoColumn>
    <DodoRow>
      <h1 class="header-padding">Switch Devices</h1>
    </DodoRow>
    <DataTable
      v-if="switches"
      :lazy="true"
      :total-records="100000"
      :value="switches"
      data-key="id"
      responsive-layout="scroll"
      :paginator="true"
      :rows="10"
      paginator-template="CurrentPageReport PrevPageLink NextPageLink RowsPerPageDropdown"
      :rows-per-page-options="[10, 50, 100]"
      current-page-report-template="Showing {first} to {last}"
      @page="onPage($event)"
    >
      <PrimeColumn field="device.id" header="ID">
        <template #body="{data}">
          {{ data.device ? formatId(data.device.id) : '-' }}
        </template>
      </PrimeColumn>
      <PrimeColumn field="device.lastPolicySyncTime" header="Last policy sync time">
        <template #body="{data}">
          {{ data.device ? formatDate(data.device.lastPolicySyncTime) : '-' }}
        </template>
      </PrimeColumn>
      <PrimeColumn field="device.lastStatusReportTime" header="Last status report time">
        <template #body="{data}">
          {{ data.device ? formatDate(data.device.lastStatusReportTime) : '-' }}
        </template>
      </PrimeColumn>
      <PrimeColumn field="device.hardwareInfo.serialNumber" header="Serial number" />
      <PrimeColumn field="imei" header="IMEI" />
      <PrimeColumn field="kioskPolicy" header="Kiosk policy">
        <template #body="{data}">
          {{ formatPolicyName(data.kioskPolicy) }}
        </template>
      </PrimeColumn>
      <PrimeColumn field="normalPolicy" header="Normal policy">
        <template #body="{data}">
          {{ formatPolicyName(data.normalPolicy) }}
        </template>
      </PrimeColumn>
      <PrimeColumn field="device.policyName" header="On normal policy">
        <template #body="{data}">
          <CheckMark :checked="data.normalPolicy === data.device?.policyName" />
        </template>
      </PrimeColumn>
    </DataTable>
  </DodoColumn>
</template>
