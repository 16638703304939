import store from './store'

export const authGuard = async (to, from, next): Promise<void> => {
  const apiKey = store.state.app.apiKey

  // Check if there is an api key, redirect to login if there isn't
  if (apiKey == null) {
    return next('/login')
  }

  // Check if there already is an enterprise, continue if there is
  if (store.state.app.enterprise && store.state.policies.mdmPolicies) {
    return next()
  }

  // Load the enterprises, redirect to login if this fails
  try {
    await store.dispatch('loadEnterprises')
    await store.dispatch('loadStats')
    await store.dispatch('loadMdmPolicies')
    return next()
  } catch (e) {
    return next('/login')
  }
}
