<script setup lang="ts">
import { useDevice } from '../composables'
const { deviceConfig } = useDevice()
import { format } from 'date-fns'
import yaml from 'js-yaml'

const props = defineProps<{
  group: any[]
  groupName: string
}>()

function formatValue(value: any, key: string): string {
  const type = deviceConfig[props.groupName][key]

  if (type === 'date') return format(new Date(value), 'dd-MM-yy HH:mm:ss')
  if (type === 'id') return value.split('/')[3]
  if (type === 'boolean') return value === true ? 'Yes' : 'No'
  if (type === 'json') return yaml.dump(value)

  return value
}

function formatKey(key: string) {
  const result = key.replace(/([A-Z])/g, ' $1')
  return result.charAt(0).toUpperCase() + result.slice(1).toLowerCase()
}
</script>

<template>
  <DataTable
    :value="group"
    class="table-padding"
    selection-mode="single">
    <PrimeColumn
      field="key"
      header-style="display:none;"
      style="width: 30%; vertical-align: baseline; background-color: #f9f9f9;"
    >
      <template #body="{ data: { key } }">
        <span class="key-field">
          {{ formatKey(key) }}
        </span>
      </template>
    </PrimeColumn>
    <PrimeColumn
      field="value"
      style="width: 70%"
      header-style="display:none;">
      <template #body="{ data: { key, value } }">
        <span v-if="value == null" class="value-null">
          null
        </span>
        <span v-else class="value-field">
          {{ formatValue(value, key) }}
        </span>
      </template>
    </PrimeColumn>
  </DataTable>
</template>

<style scoped>
.table-padding {
  border: 2px solid #f0f0f0;
}
.value-null {
  color: #dadada;
}
.key-field {
  display: block;
  font-weight: bold;
  text-align: right;
  color: #5a5a5a;
  text-transform: uppercase;
}
.value-field {
  white-space: pre-wrap;
  font-family: monospace;
}
</style>
