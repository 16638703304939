<script setup lang="ts">
import { computed } from 'vue'
import { useStore } from 'vuex'

import { EnterpriseSchema } from '@/generated/sdk'

const store = useStore()

const enterprise = computed<EnterpriseSchema>(() => store.state.app.enterprise)
</script>

<template>
  <DodoColumn>
    <DodoRow>
      <h1>{{ enterprise.enterpriseDisplayName }} ({{ enterprise.name }})</h1>
    </DodoRow>
    <DodoCard>
      <h3>QR Code</h3>
      <QrCode />
    </DodoCard>
  </DodoColumn>
</template>
